import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, Typography } from '@material-ui/core';

import { StaticImage } from "gatsby-plugin-image"

import { Container, Row, Col, Card, Table } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import Download from "../components/download"

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Payonline = () => (
  <Layout>
    <Seo title="Pay Online" description="Bank and other details to pay online" />
    <ThemeProvider theme={theme}>
<Box style={{ position:'relative', bottom:40 }}>
<Container>
    <Row className="justify-content-center bg-white">
        <Col xs={12} lg={8}>
        <Card className="mb-2 mt-5 text-center">
  <Card.Body>

  <Typography variant="h4" gutterBottom style={{ color:'#453260' }} className="text-center">PAY ONLINE</Typography>

      <Box align="center" mb={3}>
  <StaticImage
      src="../images/IDFC.jpg"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Pay Online"
      layout="fixed"
    />
    </Box>

    <Table hover>
  <tbody>
    <tr>
      <td><Typography variant="body2" align="right"><b>Bank Name :</b></Typography></td>
      <td><Typography variant="body2" align="left"><b>IDFC FIRST BANK</b></Typography></td>
    </tr>
    <tr>
      <td><Typography variant="body2" align="right"><b>Account Number :</b></Typography></td>
      <td><Typography variant="body2" align="left"><b>10075067703</b></Typography></td>
    </tr>
    <tr>
      <td><Typography variant="body2" align="right"><b>Account Holder Name :</b></Typography></td>
      <td><Typography variant="body2" align="left"><b>Dr B R Ambedkar college of law</b></Typography></td>
    </tr>
    <tr>
      <td><Typography variant="body2" align="right"><b>IFSC :</b></Typography></td>
      <td><Typography variant="body2" align="left"><b>IDFB0080181</b></Typography></td>
    </tr>

    <tr>
      <td><Typography variant="body2" align="right"><b>Branch :</b></Typography></td>
      <td><Typography variant="body2" align="left"><b>Vidyaranyapura</b></Typography></td>
    </tr>
  </tbody>
</Table>
</Card.Body>
</Card>
 
</Col>

<Col xs={12} lg={4} className="bg-white">
        <Card className="shadow mb-2 bg-light mt-5">
  <Card.Body>
<Download/>
</Card.Body>
</Card>
 
</Col>

    </Row>
</Container>
</Box>

</ThemeProvider>
  </Layout>
)

export default Payonline
